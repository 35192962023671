/*--------------------
  TEAM
--------------------*/
@import "src/assets/styles/helpers/utils";

.team {
  padding: 120px 0 0;

  @include media($tabletL) {
    padding: 60px 0 0;
  }

  &__block {
    max-width: 780px;
    margin: 0 auto;
  }

  .swiper {
    @include media($laptopM) {
      padding-bottom: 56px;
    }
  }

  .swiper-pagination {
    height: 32px !important;
    bottom: 0 !important;
  }

  .swiper-pagination-bullet {
    position: relative;
    @include size(32px);
    min-width: 32px;
    margin: 0 8px !important;
    background-color: transparent;
    border: 1px solid transparent;
    opacity: 1;
    cursor: pointer;
    transition: border 0.15s linear;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      @include size(10px);
      background-image: $primary-2;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 100%;
      transform: translate(-50%, -50%);
    }

    &-active {
      border: 1px solid $dark-primary;
    }
  }

  &__slider {
    margin-top: 60px;
    display: flex;
    align-items: center;

    @include media($tabletL) {
      margin-top: 40px;
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__card {
    max-width: 360px;
    min-height: 600px;
    padding: 24px;
    color: $white;
    background-color: $surface;
    border-radius: 12px;

    @include media($tabletL) {
      max-width: 342px;
      min-height: 550px;
      padding: 16px;
    }

    img {
      margin-bottom: 12px;
      border-radius: 6px;
    }

    &-separator {
      display: block;
      @include size(40px, 2px);
      margin: 12px 0;
      background-image: $primary-2;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      @include media($tabletL) {
        margin: 8px 0;
      }
    }

    .lead {
      margin-bottom: 2px;
    }
  }
}
