/*--------------------
  CARD
--------------------*/
@import "src/assets/styles/helpers/utils";

.card {
  display: flex;
  padding: 32px;
  color: $white;
  background-color: $surface;
  backdrop-filter: blur(10px);
  border-radius: 12px;

  @include media($tabletL) {
    padding: 16px;
  }

  > * {
    display: flex;
  }

  &--row {
    display: flex;
    flex-wrap: wrap;

    > * {
      width: 50%;
      flex-direction: column-reverse;

      &:not(:nth-last-of-type(-n+2)) {
        margin-bottom: 24px;

        @include media($tabletL) {
          margin-bottom: 12px;
        }
      }
    }
  }

  &--column {
    flex-direction: column;

    > * {
      display: flex;
      flex-direction: column-reverse;

      &:not(:last-child) {
        margin-bottom: 24px;

        @include media($tabletL) {
          margin-bottom: 16px;
        }
      }
    }
  }

  &__separator {
    @include size(40px, 2px);
    margin: 6px 0;
    background-image: $primary-2;

    @include media($tabletL) {
      margin: 1px 0;
    }
  }
}
