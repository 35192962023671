/*--------------------
  SOCIALS
--------------------*/
@import "../helpers/utils";

.socials {
  margin: 0 0 -24px -24px;

  @include media($tabletL) {
    margin: 0 0 -16px -16px;
  }

  > * {
    display: flex;
    justify-content: center;
    margin: 0 0 24px 24px;
    width: 100%;
    max-width: 202px;

    @include media($tabletL) {
      margin: 0 0 16px 16px;
    }

    @include media($mobileL) {
      max-width: 100%;
    }
  }

  &--column {
    display: flex;
    flex-direction: column;

    > * {
      width: 100%;
      max-width: 202px;
    }
  }

  &--row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > * {
      @include media($laptopS) {
        max-width: 100%;
        width: calc(50% - 24px);
      }

      @include media($tabletL) {
        width: calc(50% - 16px);
      }

      @include media($mobileL) {
        width: 100%;
      }
    }
  }

  &__link {
    width: 100%;
  }
}
