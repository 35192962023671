/*--------------------
  MINT
--------------------*/
@import "src/assets/styles/helpers/utils";

.mint {
  background-image: url("../../images/mint-bg.png");
  background-size: cover;
  background-position: 50% center;
  background-repeat: no-repeat;

  &__container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    min-height: 100vh;
    padding-top: 124px;
    color: $white;

    @include media($tabletL) {
      flex-direction: column-reverse;
      align-items: center;
      overflow: hidden;
    }
  }

  &__main {
    display: flex;

    .counter {
      margin-right: 16px;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 60px;
    height: 60px;
    border: 4px solid $dark-primary;
    border-top: 4px solid transparent;
    border-radius: 100px;
    animation: rotate 0.5s infinite linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    @include media($tabletL) {
      margin-top: 16px;
    }
  }

  &__price {
    margin: 4px 0 40px;

    @include media($tabletL) {
      margin: 2px 0 24px;
    }
  }

  &__account {
    margin-top: 16px;
    padding: 24px;
    background: $surface;
    backdrop-filter: blur(5px);
    border-radius: 6px;

    @include media($tabletL) {
      padding: 16px;
    }

    > * {
      &:not(:last-child) {
        margin-bottom: 4px;

        @include media($tabletL) {
          margin-bottom: 2px;
        }
      }
    }
  }

  &__address {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__content {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-bottom: 90px;

    @include media($tabletL) {
      padding: 0;
    }

    .button {
      width: 100%;
    }
  }

  &__images {
    position: relative;
    z-index: 0;
    width: 100%;

    @include media($tabletL) {
      margin-top: auto;
      @include size(auto, 300px);
    }

    img {
      position: absolute;
      max-width: none;
      bottom: 0;
      transition: right 1s linear, width 1s linear, height 1s linear;
    }
  }
}
