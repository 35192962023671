/* --------------------
  VARIABLES
-------------------- */

// COLOR
$white: #ffffff;
$black: #000000;

$neutral-1: #ffffff;
$neutral-2: #0f1b33;

$error: #d92b48;

$light-primary: #FFFF9C;
$dark-primary: #FFE656;
$primary-1: linear-gradient(94.1deg, $light-primary 0%, $dark-primary 11.11%, $light-primary 48.09%, $dark-primary 60.59%, $light-primary 100%);
$primary-2: linear-gradient(180deg, $light-primary 0%, $dark-primary 69.97%, $light-primary 100%);

$surface: rgba(0, 43, 128, 0.2);

$background: linear-gradient(180deg, #33AAFF 0%, #49D3FB 100%);

// FONT
$ff-base: "Montserrat", "Arial", sans-serif;
$secondary-ff: "Impact", "Arial", sans-serif;
$fw-base: 500;
$fs-base: normal;
$fz-base: 16px;
$lh-base: 24px;

// MEDIA
$mobileS: 320px;
$mobileM: 375px;
$mobileL: 425px;
$tabletS: 480px;
$tabletM: 640px;
$tabletL: 768px;
$laptopS: 992px;
$laptopM: 1024px;
$laptopL: 1200px;
$desktopS: 1440px;
$fullHD: 1920px;

// COMPONENTS STYLES VARIABLES
$container: 1230px;
