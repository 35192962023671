/*--------------------
  SCROLL SLIDER
--------------------*/
@import "../helpers/utils";

.scroll-slider {
  height: 510px;

  @include media($tabletL) {
    height: 296px;
  }

  & > * {
    height: 240px;

    @include media($tabletL) {
      height: 140px;
      background-size: cover;
    }
  }

  > *:first-child {
    background-image: url("../../images/slide1.png");
    margin-bottom: 30px;

    @include media($tabletL) {
      margin-bottom: 16px;
    }
  }

  > *:last-child {
    background-image: url("../../images/slide2.png");
  }
}
