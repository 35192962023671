/*--------------------
  MODAL
--------------------*/
@import "src/assets/styles/helpers/utils";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1010;

  display: flex;
  @include size(100vw, 100%);
  min-height: 100vh;
  padding: 32px;
  opacity: 0;
  background-color: rgba($neutral-2, 0.6);
  overflow: auto;
  pointer-events: none;
  transition: opacity 0.15s linear;

  &--error {
    .modal__inner {
      max-width: 400px;
      border: none;
      box-shadow: none;
    }

    .modal__header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px 24px 16px;
      border: none;

      @include media($tabletL) {
        padding: 16px 16px 12px;
      }
    }

    .modal__title {
      margin-bottom: 12px;

      @include media($tabletL) {
        margin-bottom: 8px;
      }
    }

    .modal__body {
      padding: 0 24px 24px;

      @include media($tabletL) {
        padding: 0 16px 16px;
      }
    }

    .button {
      margin-top: 24px;
      width: 100%;

      @include media($tabletL) {
        margin-top: 16px;
      }
    }
  }

  &.open {
    opacity: 1;
    pointer-events: auto;
  }

  &.error {
    .modal__inner {
      border-color: $error;
    }

    .modal__header {
      border-bottom-color: $error;
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    max-width: 440px;
    margin: auto;
    background-color: $white;
    border: 1px solid transparent;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

  &__btn-close {
    position: absolute;
    top: -32px;
    right: -32px;
    @include size(32px);
    min-width: 32px;
    font-size: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 2px;
      background-color: rgba($white, 0.5);
      transform: translate(-50%, -50%) rotate(45deg);
      transition: background-color 0.15s linear;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover,
    &:focus {
      &::before,
      &::after {
        background-color: $white;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid rgba($surface, 0.2);

    svg {
      margin-right: 8px;
    }

    @include media($tabletL) {
      padding: 12px 16px;
    }
  }

  &__body {
    padding: 16px 24px 24px;

    @include media($tabletL) {
      padding: 12px 16px;
    }
  }

  &__title {
    color: $neutral-2;
  }
}
