/*--------------------
  COUNTER
--------------------*/
@import "src/assets/styles/helpers/utils";

.counter {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 160px;
  padding: 10px;
  background-color: $surface;
  backdrop-filter: blur(5px);
  border-radius: 6px;
  border: 1px solid $dark-primary;

  &__input {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: $white;
    background-color: transparent;
    border: none;
    text-align: center;
  }

  &__btn {
    position: relative;
    @include size(24px);
    min-width: 24px;
    background-color: transparent;
    border: none;
    font-size: 0;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      @include size(14px, 2px);
      background-color: $white;
      transform: translate(-50%, -50%);
      transition: background-color 0.15s linear, opacity 0.15s linear;
    }

    &:hover,
    &:focus {
      &::after,
      &::before {
        background-color: $dark-primary;
      }
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &--remove {
      &::after {
        display: none;
      }
    }

    &--add {
      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
}
