/*--------------------
  STORY
--------------------*/
@import "src/assets/styles/helpers/utils";

.story {
  padding: 120px 0 0;

  @include media($tabletL) {
    padding: 60px 0 0;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media($tabletL) {
      flex-direction: column;
    }
  }

  &__block {
    width: 100%;
    max-width: 574px;
    margin-right: 15px;

    @include media($tabletL) {
      max-width: 100%;
      margin: 0 0 40px;
    }
  }

  .card {
    width: 100%;
    max-width: 570px;

    @include media($tabletL) {
      max-width: 100%;
    }
  }
}
