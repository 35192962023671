/*--------------------
  LOADER
--------------------*/
@import "../helpers/utils";

.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include size(100vw, 100vh);
  background-image: url("../../images/loading-bg.jpg"), $background;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s linear;

  &.open {
    opacity: 1;
    pointer-events: auto;
  }

  &__img {
    position: relative;
    transition: top 0.15s linear;
    animation: progressImage 1s infinite alternate linear;
  }

  &__progress {
    position: relative;
    @include size(300px, 16px);
    border: 1px solid $white;
    border-radius: 6px;
    overflow: hidden;
  }

  &__bar {
    position: absolute;
    @include size(0, 100%);
    background-image: $primary-1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: progress 1.5s 1 forwards linear;
  }

  &__text {
    margin: 32px 0;
  }
}

@keyframes progressImage {
  0% {
    top: 0;
  }
  100% {
    top: 20px;
  }
}

@keyframes progress {
  0% {
    width: 0;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
}


