/*--------------------
  RUNLINE
--------------------*/
@import "../helpers/utils";

.runline {
  padding: 32px;
  border: 1px solid $dark-primary;
  background-color: $surface;

  @include media($tabletL) {
    padding: 16px;
  }

  svg {
    width: 100%;
    min-width: 100%;

    @include media($tabletL) {
      height: 55px;
    }
  }

  .overlay {
    display: none;
  }
}
