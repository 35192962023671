/*--------------------
  COMMUNITY
--------------------*/
@import "src/assets/styles/helpers/utils";

.community {
  padding: 80px 0 20px;

  @include media($tabletL) {
    padding: 60px 0 0;
  }

  &__block {
    max-width: 900px;
    margin: 0 auto;
  }

  .scroll-slider {
    margin-top: 60px;

    @include media($tabletL) {
      margin-top: 40px;
    }
  }
}
