/*--------------------
  FOOTER
--------------------*/
@import "src/assets/styles/helpers/utils";

.footer {
  padding: 214px 0 314px;
  background-image: url("../../images/footer-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  @include media($desktopS) {
    padding: 160px 0 305px;
  }

  @include media($tabletL) {
    padding: 60px 0 130px;
  }

  &__block {
    max-width: 988px;
    margin: 0 auto;
  }

  .socials {
    margin-top: 80px;

    @include media($tabletL) {
      margin-top: 40px;
    }
  }
}
