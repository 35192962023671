/*--------------------
  ABOUT
--------------------*/
@import "src/assets/styles/helpers/utils";

.about {
  padding: 80px 0;

  @include media($tabletL) {
    padding: 60px 0;
  }

  &__block {
    max-width: 900px;
    margin: 0 auto;
  }

  &__quote {
    margin: 80px 0;
    padding: 60px;
    background: $surface;
    backdrop-filter: blur(10px);
    border-radius: 12px;
    border: 1px solid $dark-primary;

    @include media($tabletL) {
      margin: 60px 0;
      padding: 24px 16px;
    }

    blockquote {
      position: relative;

      @include media($tabletL) {
        padding: 38px 0;
      }

      &::before,
      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        @include size(50px, 42px);
        background-image: url("../../icons/quote.svg");
        background-repeat: no-repeat;
        background-size: cover;

        @include media($tabletL) {
          @include size(32px, 26px)
        }
      }

      &::after {
        top: auto;
        left: auto;
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
      }
    }

    p {
      padding: 0 74px;
      @include font(40px, 50px, 400);
      font-family: $secondary-ff;
      text-align: center;
      color: $white;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      @include media($tabletL) {
        padding: 0;
        @include font(26px, 36px, 400);
      }
    }

    b {
      color: $dark-primary;
    }

    cite {
      position: relative;
      display: block;
      margin-top: 40px;
      font-family: $secondary-ff;
      @include font(50px, 60px, 700);
      text-align: center;
      color: $dark-primary;
      padding-top: 12px;

      @include media($tabletL) {
        margin-top: 16px;
        @include font(18px, 28px, 700);
      }

      &::before {
        position: absolute;
        content: "";
        @include size(40px, 2px);
        background-color: $dark-primary;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__video {
    display: flex;
    justify-content: center;

    video {
      @include size(100%);
      border-radius: 6px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: calc(80px - 24px) 0 0;

    @include media($tabletL) {
      margin: calc(40px - 16px) 0 0;
    }

    > * {
      width: 100%;
      max-width: 225px;
      margin: 12px;
      padding: 0;

      @include media($tabletL) {
        margin: 8px;
      }
    }
  }
}
