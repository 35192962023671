/*--------------------
  TEXT
--------------------*/
@import "src/assets/styles/helpers/utils";

.h1 {
  @include font(100px, 110px);
  font-family: $secondary-ff;
  text-transform: uppercase;
  color: $white;

  @include media($tabletL) {
    @include font(60px, 70px);
  }
}

.desc {
  margin-top: 40px;

  @include media($tabletL) {
    margin-top: 24px;
  }
}

.text-center {
  text-align: center;
}

.title-updater {
  -webkit-text-stroke: 1px $neutral-2;
  text-shadow: $light-primary 3px 3px;
}

.h2 {
  @include font(62px, 72px);
  font-family: $secondary-ff;
  text-transform: uppercase;
  color: $white;

  @include media($tabletL) {
    @include font(42px, 52px);
  }
}

.h3 {
  @include font(40px, 50px);
  font-family: $secondary-ff;
  text-transform: uppercase;
  color: $white;

  @include media($tabletL) {
    @include font(30px, 40px);
  }
}

.lead {
  @include font(20px, 30px, 700);
  color: $white;

  @include media($tabletL) {
    @include font(18px, 28px, 700);
  }
}

.lead-block {
  display: block;
  background: $primary-2;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 12px;

  @include media($tabletL) {
    margin-bottom: 8px;
  }
}

