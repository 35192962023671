/*--------------------
  HERO
--------------------*/
@import "src/assets/styles/helpers/utils";

.hero {
  position: relative;
  @include size(100%, 1760px);
  background-image: url("../../images/header-bg.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  overflow: hidden;

  &__title {
    position: relative;
    margin-top: 190px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__atm {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 500px;
    transform: translateX(-50%);

    @include media($tabletL) {
      width: 300px;
    }

    span {
      display: block;

      a {
        display: block;
      }

      svg {
        width: 100%;
        height: 100%;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.2;
      }

      &:not(:first-child) {
        position: absolute;
        transition: opacity 0.15s linear;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      &:nth-child(2) {
        top: 38%;
        left: 28%;
        width: 46%;
        height: 9%;
      }

      &:nth-child(3) {
        top: 51%;
        left: 28%;
        width: 26%;
        height: 4%;
      }

      &:nth-child(4) {
        top: 59%;
        left: 28%;
        width: 39%;
        height: 4%;
      }
    }
  }

  &__trumps {
    position: absolute;
    bottom: 0;
    left: 50%;
    max-width: 1620px;
    height: 480px;
    width: 100%;
    transform: translateX(-50%);
    pointer-events: none;

    @include media($tabletL) {
      height: 300px;
    }

    img {
      position: absolute;
      left: 50%;
      bottom: 0;
      max-width: none;
      height: 100%;
      transform: translateX(-50%);
    }
  }
}
