/*--------------------
  ROADMAP
--------------------*/
@import "src/assets/styles/helpers/utils";

.roadmap {
  position: relative;
  z-index: 1;
  padding: 120px 0 466px;
  background-image: url("../../images/roadmap-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  overflow: hidden;

  img {
    position: absolute;
    z-index: -1;
    bottom: 0;

    &:nth-of-type(2) {
      left: -120px;

      @include media($laptopL) {
        left: -85px;
        @include size(300px);
      }
    }

    &:nth-of-type(1) {
      left: 180px;

      @include media($laptopL) {
        left: 95px;
        @include size(280px);
      }
    }

    &:nth-of-type(3) {
      right: 180px;

      @include media($laptopL) {
        right: 95px;
        @include size(280px);
      }

      @include media($tabletL) {
        display: none;
      }
    }

    &:nth-of-type(4) {
      right: -120px;

      @include media($laptopL) {
        right: -85px;
        @include size(300px);
      }

      @include media($tabletL) {
        display: none;
      }
    }
  }

  @include media($tabletL) {
    padding: 60px 0 300px;
  }

  &__phaser {
    margin-top: 60px;
    padding: 60px 86px;
    color: $white;
    background-color: $surface;
    backdrop-filter: blur(10px);
    border-radius: 12px;
    border: 1px solid $light-primary;

    @include media($tabletL) {
      margin-top: 40px;
      padding: 32px 16px;
    }

    svg {
      width: 100%;
      min-width: 100%;

      @include media($tabletL) {
        height: 43px;
      }
    }

    b {
      display: block;
      margin-bottom: 2px;
    }

    .lead {
      margin-bottom: 0;
    }
  }

  &__phase {
    display: flex;

    &:first-child {
      .roadmap__step {
        &::after {
          display: none;
        }
      }
    }

    &:last-child {
      .roadmap__step {
        &::before {
          display: none;
        }
      }
    }

    &:not(:last-child) {
      .roadmap__content {
        margin-bottom: 40px;

        @include media($tabletL) {
          margin-bottom: 32px;
        }
      }
    }
  }

  &__step {
    position: relative;
    margin-right: 60px;
    padding-top: 20px;

    @include media($tabletL) {
      margin-right: 12px;
    }

    &-point {
      position: relative;
      @include size(48px);
      min-width: 48px;
      border: 1px solid $dark-primary;
      border-radius: 100%;

      @include media($tabletL) {
        @include size(32px);
        min-width: 32px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        @include size(16px);
        background-image: $primary-2;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 100%;
        transform: translate(-50%, -50%);

        @include media($tabletL) {
          @include size(10px);
        }
      }
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      @include size(1px, 100%);
      background-color: $dark-primary;
      transform: translate(-50%);
    }

    &::before {
      top: 68px;
      height: calc(100% - 68px);

      @include media($tabletL) {
        top: 52px;
        height: calc(100% - 52px);
      }
    }

    &::after {
      top: 0;
      height: 20px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__list {
    margin-top: 24px;

    @include media($tabletL) {
      margin-top: 12px;
    }

    > *:not(:last-child) {
      margin-bottom: 16px;

      @include media($tabletL) {
        margin-bottom: 12px;
      }
    }
  }
}
