/*--------------------
  ART
--------------------*/
@import "src/assets/styles/helpers/utils";

.art {
  position: relative;
  z-index: 1;
  padding: 100px 0 170px;
  background-image: url("../../images/art-bg.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @include media($tabletL) {
    padding: 60px 0 300px;
  }

  img {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;

    &:nth-of-type(1) {
      right: 260px;

      @include media($tabletL) {
        right: auto;
        left: 0;
        @include size(280px);
      }
    }

    &:nth-of-type(2) {
      @include media($tabletL) {
        right: auto;
        left: 180px;
        @include size(300px);
      }
    }
  }

  &__block {
    max-width: 600px;
  }

  .card {
    margin-top: 40px;

    @include media($tabletL) {
      margin-top: 24px;
    }
  }
}
