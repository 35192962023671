/*--------------------
  COMMON STYLES
--------------------*/
@import "src/assets/styles/helpers/utils";

*,
*::after,
*::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: initial;
}

p {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

blockquote,
figure {
  margin: 0;
}

cite {
  font-style: normal;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: vertical;
}

input,
select,
textarea,
button {
  padding: 0;
  font: inherit;
  background-clip: padding-box;
  border: 1px solid #666666;
  border-radius: 0;
  outline: none;
  appearance: none;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

html,
body {
  @include font($fz-base, $lh-base, $fw-base);
  font-family: $ff-base;
  color: $neutral-2;
  background-image: $background;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include media($tabletL) {
    @include font(15px, $lh-base, $fw-base);
  }
}

.background {
  position: relative;
  z-index: 2;
  background-image: $background;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.container {
  max-width: $container;
  margin: 0 auto;
  padding: 0 15px;
}

.visible-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.overflow-hidden {
  overflow: hidden;
}
