/*--------------------
  BUTTON
--------------------*/
@import "src/assets/styles/helpers/utils";

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  @include size(auto, 60px);
  padding: 18px 36px;
  background-color: transparent;
  background-image: $primary-1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;

  @include font(14px, 24px, 700);
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $neutral-2;
  transition: box-shadow 0.15s linear, opacity 0.15s linear;

  svg {
    margin-right: 8px;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 32px rgba(255, 253, 151, 0.8);
  }

  &:disabled,
  &.disabled {
    opacity: 0.8;
    pointer-events: none;
  }

  &--large {
    height: 72px;
  }

  &--secondary {
    background-image: none;
    border: 2px solid $dark-primary;
  }
}
