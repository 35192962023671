/*--------------------
  HEADER
--------------------*/
@import "src/assets/styles/helpers/utils";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  padding: 24px 0;
  transition: opacity 0.15s linear;

  @include media($tabletL) {
    padding: 16px 0;
  }

  &__btn {
    padding: 18px 36px;

    @include media($tabletL) {
      padding: 12px 24px;
      height: 48px;
    }
  }

  &__logo {
    margin: 0 15px;

    @include media($mobileM) {
      @include size(60px, 30px);
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
