/*--------------------
  MENU
--------------------*/
@import "../helpers/utils";

.menu {
  position: fixed;
  top: 0;
  left: 0;
  @include size(100vw, 100vh);
  overflow: auto;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s linear;

  &.open {
    opacity: 1;
    pointer-events: auto;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @include media($laptopS) {
      flex-direction: column;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 715px;
    margin-left: -15px;
    margin-bottom: -20px;

    @include media($laptopL) {
      max-width: 615px;
    }

    @include media($tabletL) {
      max-width: 500px;
    }

    @include media($mobileL) {
      flex-direction: column;
    }

    > * {
      margin-left: 15px;
      margin-bottom: 20px;
    }
  }

  &__list {
    > *:not(:last-child) {
      margin-bottom: 40px;

      @include media($tabletL) {
        margin-bottom: 20px;
      }
    }
  }

  &__link {
    text-decoration: none;
    transition: color 0.15s linear, text-shadow 0.15s linear;

    &:hover,
    &:focus {
      color: $dark-primary;
      text-shadow: $white 3px 3px;
    }

    &.disabled {
      opacity: 0.8;
      pointer-events: none;
    }
  }

  &__socials {
    margin-left: 15px;

    @include media($laptopS) {
      margin: 30px 0 0;
    }
  }

  .socials {
    @include media($laptopS) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    > * {
      @include media($mobileM) {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__btn-close {
    position: absolute;
    top: 32px;
    right: 32px;
    @include size(40px);
    font-size: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @include media($laptopL) {
      top: 20px;
      right: 20px;
    }

    @include media($tabletL) {
      @include size(16px);
    }

    &:hover,
    &:focus {
      &::after,
      &::before {
        background-color: $dark-primary;
      }
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      @include size(26px, 2px);
      background-color: $neutral-2;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: background-color 0.15s linear;

      @include media($tabletL) {
        @include size(16px, 2px);
      }
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__wrapper {
    padding: 120px;
    background-image: $background;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include media($laptopL) {
      padding: 60px;
    }

    @include media($laptopS) {
      padding: 60px 30px 30px;
    }

    @include media($tabletL) {
      padding: 46px 16px 16px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    @include size(100%);
    background-color: rgba($neutral-2, 0.5);
  }
}
